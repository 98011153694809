import {useAuthStore} from "~/store/authStore";

export default async function refreshAll(done: () => void) {
    const userStore = useAuthStore()
    refreshNuxtData().then(() => {
        done()
        if (userStore.user) {
            userStore.getUser(true)
        }
    }).finally(() => {
        done()
    })
}
